import PartitionModel    from 'Models/partition/PartitionModel';
import SubPartitionModel from 'Models/partition/SubPartitionModel';
import AbstractApiModel  from 'modelx/models/abstracts/AbstractApiModel';
import browserHistory    from 'tools/browserHistory';

type P = Record<string, string | number | (string | number)[]> | string;

const path = (path: string, params?: P) => {
	const startsWithHttp = path.startsWith('http');

	let p = (startsWithHttp ? '' : '/') + path;

	if (params) {
		if (typeof params === 'string') {
			p += '?' + params;
		} else {
			p += '?' + new URLSearchParams(params as never).toString();
		}
	}

	if (startsWithHttp) {
		return p;
	}

	return p.replace('//', '/');
};

const to = (str: string, redirect?: boolean, params?: P) => {
	const url = path(str, params);

	if (redirect) {
		browserHistory.push(url);
	}

	return url;
};

const toP = (str: string, p: PartitionModel, redirect?: boolean, params?: P) => {
	return p.isLoaded ? to(`${p.url}${`/${str}`.replace('//', '/')}`, redirect, params) : '';
};

const toSP = (str: string, sp: SubPartitionModel, redirect?: boolean, params?: P) => {
	return sp.isLoaded ? toP(`sub_partitions/${sp.id}/${str}`, sp.partition, redirect, params) : '';
};

export default {
	toAccountingCodeList: (r?: boolean, p?: P) => to(`accounting_codes`, r, p),
	toAccreditation: (id: id, r?: boolean) => to(`accreditations/${id}`, r),
	toAccreditationList: (r?: boolean, p?: P) => to(`accreditations`, r, p),
	toAccreditationSubPartition: (id: id, r?: boolean) => to(`accreditation_sub_partitions/${id}`, r),
	toAction: (id: id, r?: boolean) => to(`actions/${id}`, r),
	toActionGroup: (id: id, r?: boolean) => to(`action_groups/${id}`, r),
	toActionGroupList: (r?: boolean, p?: P) => to(`action_groups`, r, p),
	toActionList: (r?: boolean, p?: P) => to(`actions`, r, p),
	toActivityTypeList: (r?: boolean, p?: P) => to(`activity_types`, r, p),
	toAgedTrialBalanceList: (r?: boolean, p?: P) => to('aged_trial_balance', r, p),
	toBillable: (id: id, r?: boolean) => to(`billables/${id}`, r),
	toBillableCreditToValidateListPage: (r?: boolean, p?: P) => to(`billables`, r, p),
	toBillableList: (r?: boolean, p?: P) => to(`billables`, r, p),
	toBlockRequest: (id: id, r?: boolean) => to(`block_requests/${id}`, r),
	toBlockRequestList: (r?: boolean, p?: P) => to(`blocked_task_zones`, r, p),
	toCompany: (id: id, r?: boolean) => to(`companies/${id}`, r),
	toCompanyList: (r?: boolean, p?: P) => to(`companies`, r, p),
	toComplianceReferential: (id: id, r?: boolean) => to(`compliance_referentials/${id}`, r),
	toDashboard: (m: AbstractApiModel, r?: boolean) => r ? m.goTo() : m.pathTo(),
	toDebitMandate: (id: id, r?: boolean) => to(`debit_mandates/${id}`, r),
	toDebitMandateToActivateListPage: (r?: boolean, p?: P) => to(`debit_mandates`, r, p),
	toEnterprise: (id: id, r?: boolean) => to(`enterprises/${id}`, r),
	toEnterpriseList: (r?: boolean, p?: P) => to(`enterprises`, r, p),
	toFiles: (r?: boolean) => to(`/files`, r),
	toHome: (r?: boolean) => to(`/`, r),
	toInstallationType: (id: id, r?: boolean) => to(`installation_types/${id}`, r),
	toInstallationTypeList: (r?: boolean, p?: P) => to(`installation_types`, r, p),
	toInvoiceItemList: (r?: boolean, p?: P) => to(`invoice_items`, r, p),
	toInvoiceList: (r?: boolean, p?: P) => to(`invoices`, r, p),
	toList: (m: typeof AbstractApiModel, r?: boolean, p?: P) => to(m.pathToList, r, p),
	toMeasure: (id: id, r?: boolean) => to(`measures/${id}`, r),
	toMeasureGroup: (id: id, r?: boolean) => to(`measure_groups/${id}`, r),
	toPartition: (id: id, r?: boolean) => to(`partitions/${id}`, r),
	toPartitionDashboard: (m: AbstractApiModel, p: PartitionModel, r?: boolean) => toP(m.path, p, r),
	toPaymentModeRuleList: (r?: boolean, p?: P) => to(`payment_mode_rules`, r, p),
	toPaymentModeTypeList: (r?: boolean, p?: P) => to(`payment_mode_types`, r, p),
	toPContact: (id: id, p: PartitionModel, r?: boolean) => toP(`contacts/${id}`, p, r),
	toRFAList: (r?: boolean, p?: P) => to(`end_year_discount_rates`, r, p),
	toRootGroupList: (r?: boolean, p?: P) => to(`root_groups`, r, p),
	toSPClient: (id: id, sp: SubPartitionModel, r?: boolean) => toSP(`clients/${id}`, sp, r),
	toSPContractIteration: (id: id, sp: SubPartitionModel, r?: boolean) => toP(`contract_iterations/${id}`, sp.partition, r),
	toSPDashboard: (m: AbstractApiModel, sp: SubPartitionModel, r?: boolean) => toSP(m.path, sp, r),
	toSPInvoice: (id: id, sp: SubPartitionModel, r?: boolean) => toP(`invoices/${id}`, sp.partition, r),
	toSPQuotation: (id: id, sp: SubPartitionModel, r?: boolean) => toP(`quotations/${id}`, sp.partition, r),
	toSPTaskZone: (id: id, p: PartitionModel, r?:boolean) => toP(`sub_partitions/${p.id}/task_zones/${id}`, p, r),
	toStaff: (id: id, r?: boolean) => to(`staff/${id}`, r),
	toStatistics: (r?: boolean) => to(`analytics/invoice`, r),
	toSubPartitionUrl: (sp: SubPartitionModel, redirect?: boolean) => toP('', sp.partition, redirect, { toSP: sp.id }),
	toSynthesisAccessRight: (r?: boolean, p?: P) => to(`synthesis_access_rights`, r, p),
	toVatList: (r?: boolean, p?: P) => to(`vats`, r, p),
	toVehicleServiceDashboard: () => to(`vehicle_dashboard`),
};
